import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import pathsShape from '../shapes/paths';

const className =
  'o-nav-link o-nav-link--menu t-font-family--body t-no-margin c-dropdown__item c-dropdown__item--sparse';

const AccountLinks = ({ paths, showPerformance }) => (
  <ul className="o-list-reset">
    <li>
      <a href={paths.profile} className={className}>
        <FormattedMessage id="shared.user_account_menu.your_profile" />
      </a>
    </li>
    <li>
      <a href={paths.activity} className={className}>
        <FormattedMessage id="shared.user_account_menu.activity" />
      </a>
    </li>
    {showPerformance && (
      <li>
        <a href={paths.performance} className={className}>
          <FormattedMessage id="shared.user_account_menu.your_performance" />
        </a>
      </li>
    )}
    <li>
      <a href={paths.introduction} className={className}>
        <FormattedMessage id="shared.user_account_menu.introduction" />
      </a>
    </li>
    <li>
      <a href={paths.signOut} data-method="delete" className={className}>
        <FormattedMessage id="shared.user_account_menu.sign_out" />
      </a>
    </li>
  </ul>
);

AccountLinks.propTypes = {
  paths: pathsShape.isRequired, // eslint-disable-line react/no-typos
  showPerformance: PropTypes.bool.isRequired
};

export default AccountLinks;
